exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-career-jsx": () => import("./../../../src/templates/career.jsx" /* webpackChunkName: "component---src-templates-career-jsx" */),
  "component---src-templates-career-single-backend-jsx": () => import("./../../../src/templates/career-single-backend.jsx" /* webpackChunkName: "component---src-templates-career-single-backend-jsx" */),
  "component---src-templates-company-about-us-jsx": () => import("./../../../src/templates/company/about-us.jsx" /* webpackChunkName: "component---src-templates-company-about-us-jsx" */),
  "component---src-templates-company-certificates-jsx": () => import("./../../../src/templates/company/certificates.jsx" /* webpackChunkName: "component---src-templates-company-certificates-jsx" */),
  "component---src-templates-company-environment-and-sustainability-jsx": () => import("./../../../src/templates/company/environment-and-sustainability.jsx" /* webpackChunkName: "component---src-templates-company-environment-and-sustainability-jsx" */),
  "component---src-templates-company-history-jsx": () => import("./../../../src/templates/company/history.jsx" /* webpackChunkName: "component---src-templates-company-history-jsx" */),
  "component---src-templates-company-our-values-jsx": () => import("./../../../src/templates/company/our-values.jsx" /* webpackChunkName: "component---src-templates-company-our-values-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-imprint-jsx": () => import("./../../../src/templates/imprint.jsx" /* webpackChunkName: "component---src-templates-imprint-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-press-jsx": () => import("./../../../src/templates/press.jsx" /* webpackChunkName: "component---src-templates-press-jsx" */),
  "component---src-templates-press-single-jsx": () => import("./../../../src/templates/press-single.jsx" /* webpackChunkName: "component---src-templates-press-single-jsx" */),
  "component---src-templates-privacy-policy-jsx": () => import("./../../../src/templates/privacy-policy.jsx" /* webpackChunkName: "component---src-templates-privacy-policy-jsx" */),
  "component---src-templates-products-cosmetics-jsx": () => import("./../../../src/templates/products/cosmetics.jsx" /* webpackChunkName: "component---src-templates-products-cosmetics-jsx" */),
  "component---src-templates-products-die-cut-lids-jsx": () => import("./../../../src/templates/products/die-cut-lids.jsx" /* webpackChunkName: "component---src-templates-products-die-cut-lids-jsx" */),
  "component---src-templates-products-foil-processing-jsx": () => import("./../../../src/templates/products/foil-processing.jsx" /* webpackChunkName: "component---src-templates-products-foil-processing-jsx" */),
  "component---src-templates-products-food-jsx": () => import("./../../../src/templates/products/food.jsx" /* webpackChunkName: "component---src-templates-products-food-jsx" */),
  "component---src-templates-products-non-food-jsx": () => import("./../../../src/templates/products/non-food.jsx" /* webpackChunkName: "component---src-templates-products-non-food-jsx" */),
  "component---src-templates-products-pharmaceuticals-jsx": () => import("./../../../src/templates/products/pharmaceuticals.jsx" /* webpackChunkName: "component---src-templates-products-pharmaceuticals-jsx" */),
  "component---src-templates-products-printing-jsx": () => import("./../../../src/templates/products/printing.jsx" /* webpackChunkName: "component---src-templates-products-printing-jsx" */),
  "component---src-templates-terms-and-conditions-jsx": () => import("./../../../src/templates/terms-and-conditions.jsx" /* webpackChunkName: "component---src-templates-terms-and-conditions-jsx" */)
}

